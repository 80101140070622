import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./App.css";
import moment from "moment";

function App() {
  const iframeRef = useRef(null);

  const [initialMessage, setinitialMessage] = useState();

  const [message, setmessage] = useState();

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'App eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJmYTg4Y2I1LWZlMTktNGM2OS'
  }

  useEffect(() => {
    const handleMessage = async (e) => {
      const { type, values } = e.data;
      if (type === "initial") {
        setinitialMessage(values);
        console.log(values);
      } else {
        let value = values;
        value.date_of_birth = moment(value.date_of_birth).format('DD/MM/YYYY');
        setmessage(value);

        const pathname = window.location.href;
        console.log(pathname);

        value &&
          await axios.post("https://bcrm.poorvika.com/api/v1/feedback", value, {
            headers: headers
          })
            .then((response) => {
              response = JSON.parse(JSON.stringify(response.data));

              if (response.errorType)
                //alert(response["errorMessage"]);
                simpleToast(response["errorMessage"])
              else {
                //alert(response["message"]);
                console.log("response : " + response);
                simpleToast(response["message"]);


                setTimeout(function () {
                  if (!pathname.includes("mobile"))
                    window.location.replace('https://poorvika.com/');
                  else {
                    var iframe = document.getElementById("test-frame");
                    iframe.src = iframe.src;
                  }
                }, 2500);

              }

              //alert("JSON send Successfully");
            }).catch((error) => {
              console.error("error : " + error);
              simpleToast("Something Went Wrong. Try again.");
            });
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const sendMessage = () => {
    if (!iframeRef.current) return;
    iframeRef.current.contentWindow.postMessage(
      {
        type: "initial",
        values: initialMessage,
      },
      "https://audit-app.poorvika.com/"
    );
  };

  // eslint-disable-next-line
  useEffect(() => {
    setTimeout(() => {
      sendMessage();
    }, 1000);
    // eslint-disable-next-line
  }, [initialMessage]);

  function simpleToast(data) {
    var x = document.getElementById("simpleToast");
    x.innerHTML = data;
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
  }

  return (
    <div>
      <iframe
        title="demo"
        className="iframe"
        ref={iframeRef}
        id="test-frame"
        width={"100%"}
        src={`https://audit-app.poorvika.com/dynamic_template/6373892e182b2d53bb66526c?align=center`}
      ></iframe>
      <div id="simpleToast">
        <span></span>
      </div>
    </div>
  );
}

export default App;
